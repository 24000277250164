import React, { useState, useEffect } from "react";
import { launchAR } from "../helpers/ar-helpers";
import Collapsible from "react-collapsible";
import "./style.css";
import {

  isMobileOnly,
} from "react-device-detect";
import Carousel from "react-elastic-carousel";

let extMap = [
  // {
  //   name: "Epi Saphir Bleu Azur",
  //   img:
  //     "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/blue.png",
  // },
  {
    name: "Monogram Rose Ballerine",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/monogram-rose.png",
  },
  {
    name: "Damier Azur Rose Ballerine",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/damir.jpg",
  },
  {
    name: "Epi Saphir Bleu Azur",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/black.png",
  },
  {
    name: "Monogram Piment",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/monogram-dark.png",
  },

  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/monogram-black.png",
  },

  //
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/beige.png",
  },
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/monogram-tri-black.png",
  },
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/monogram-brown.png",
  },
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/gold.png",
  },
  // fake
];
let intMap = [
  {
    name: "Damier Azur Rose Ballerine",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/pink.png",
  },
  {
    name: "Epi Saphir Bleu Azur",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/blue.png",
  },
  {
    name: "Monogram Piment",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/piment.png",
  },
  // fake
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/gold-extra.png",
  },
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/dark-red-extra.png",
  },

  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/purple-extra.png",
  },

  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/orange-extra.png",
  },
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/brown-extra.png",
  },
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/rose-extra.png",
  },
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/orange-two-extra.png",
  },
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/salmon-extra.png",
  },
  {
    name: "",
    img: "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/blue-two-extra.png",
  },
];
const TriggerElement = "span";

function Form() {
  const [activeExt, setActiveExt] = useState(
    "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/new/black.png"
  );
  const [activeInt, setActiveInt] = useState(
    "https://solutions-engineering.s3.amazonaws.com/vivatech/extra-swatches/final-interior/blue.png"
  );
  const [imageLoaded, setImageLoaded] = useState(false);
  const setActiveImage = () => {
    let finish = window.configurator.getConfiguration().Finish;
    console.log(finish);

    extMap.forEach((e) => {
      if (e.name == window.configurator.getConfiguration().Finish) {
        setActiveExt(e.img);
      } else {
      }
    });
    intMap.forEach((e) => {
      if (e.name === window.configurator.getConfiguration().Finish) {
        setActiveInt(e.img);
      }
    });
  };
  window.privateConfig.on("setConfiguration", setActiveImage);

  function ExtTrigger() {
    return (
      <div className="section-container">
        <div>
          <b style={{ fontSize: "20px" }} className="form-step-title">
            01 Select exterior material & color
          </b>{" "}
          <br />
        </div>
        <div className="form-container">
          <div className="item">
            <p className="step-section-title">Exterior Material:</p>{" "}
          </div>
          <div className="item item-img">
            <img
              className={`smooth-image image-${
                imageLoaded ? "visible" : "hidden"
              }`}
              onLoad={() => setImageLoaded(true)}
              src={activeExt}
              style={{ height: "50px", width: "50px" }}
            />{" "}
            {"+"}
          </div>
        </div>
        <div className="section-border"></div>
      </div>
    );
  }
  function Trigger() {
    return (
      <div className="section-container">
        <div>
          <b style={{ fontSize: "20px" }} className="form-step-title">
            02 Select lining material & color
          </b>{" "}
          <br />
        </div>
        <div className="form-container">
          <div className="item">
            <p className="step-section-title">Interior Material:</p>{" "}
          </div>
          <div className="item item-img">
            <img
              className={`smooth-image image-${
                imageLoaded ? "visible" : "hidden"
              }`}
              onLoad={() => setImageLoaded(true)}
              src={activeInt}
              style={{ height: "50px", width: "50px" }}
            />{" "}
            {"+"}
          </div>
        </div>
        <div className="section-border"></div>
      </div>
    );
  }
  function ARTrigger() {
    return (
      <div className="section-container">
        <div>
          <b style={{ fontSize: "20px" }} className="form-step-title">
            03 View in your space with AR
          </b>{" "}
          <br />
        </div>
        <div className="form-container">
          <div className="item">
            <p className="step-section-title">See Your Malle Vendome:</p>{" "}
            <span id="status"></span>{" "}
          </div>
          <div className="item item-img">
            <a href="" id="ar-ios-link" style={{ display: "none" }}></a>

            <button
              className="ar-btn-desktop"
              id="ar-button"
              style={{ height: "50px", width: "50px" }}
              onClick={() =>
                launchAR(window.configurator.getConfiguration().Finish)
              }
            >
              AR
            </button>
          </div>
        </div>
        <div className="section-border"></div>
      </div>
    );
  }

  const onClick = (attr, val, img) => {
    console.log(attr, val);
    console.log("img click");
    window.configurator.setConfiguration({ [attr]: val });
    // setActiveExt(img);
    console.log(img);
  };

  useEffect(() => {
    // Loaded
  }, []);

  return (
    <div>
      {isMobileOnly ? (
        <Carousel
          itemsToShow={1}
          style={{ width: "100vw", maxWidth: "100vw", flexWrap: "wrap" }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="section-container">
              <div>
                <b style={{ fontSize: "20px" }} className="form-step-title">
                  01 Select exterior material & color
                </b>{" "}
                <br />
              </div>
              <div className="form-container">
                <div className="item"></div>
                <div className="item item-img"></div>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {extMap.map((e) => {
                return (
                  <div style={{ margin: "5px" }}>
                    <img
                      src={e.img}
                      style={{ height: "50px", width: "50px" }}
                      key={e}
                      id={e.name}
                      onClick={(e) =>
                        onClick("Finish", e.target.id, e.target.src)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{}}>
            <div className="section-container">
              <div>
                <b style={{ fontSize: "20px" }} className="form-step-title">
                  02 Select lining material & color
                </b>{" "}
                <br />
              </div>
              <div className="form-container">
                <div className="item"></div>
                <div className="item item-img"></div>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {intMap.map((e) => {
                return (
                  <div style={{ margin: "5px" }}>
                    <img
                      src={e.img}
                      style={{ height: "50px", width: "50px" }}
                      key={e}
                      id={e.name}
                      onClick={(e) =>
                        onClick("Finish", e.target.id, e.target.src)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <a href="" id="ar-ios-link" style={{ display: "none" }}></a>
            <p id="status"></p>
            <button
              className="ar-btn"
              id="ar-button"
              onClick={() =>
                launchAR(window.configurator.getConfiguration().Finish)
              }
            >
              View in your space
            </button>
          </div>
        </Carousel>
      ) : (
        <div className="form-container">
          <Collapsible
            trigger={<ExtTrigger />}
            // triggerStyle={{ backgroundColor: "grey" }}
          >
            <div className="content-container">
              {extMap.map((e, i) => {
                return (
                  <div className="flex-item">
                    <img
                      className="swatch"
                      src={e.img}
                      style={{ height: "50px", width: "50px" }}
                      key={JSON.stringify(e)}
                      id={e.name}
                      onClick={(e) =>
                        onClick("Finish", e.target.id, e.target.src)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </Collapsible>

          <Collapsible
            trigger={<Trigger />}
            // triggerStyle={{ backgroundColor: "grey" }}
          >
            <div className="content-container">
              {intMap.map((e) => {
                return (
                  <div className="flex-item">
                    <img
                      className="swatch"
                      src={e.img}
                      style={{ height: "50px", width: "50px" }}
                      key={e}
                      id={e.name}
                      onClick={(e) =>
                        onClick("Finish", e.target.id, e.target.src)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </Collapsible>
          <div>
            <ARTrigger />
            {/* <Collapsible
              trigger={<ARTrigger/>}
              // triggerStyle={{ backgroundColor: "grey" }}
            >
              <div className="content-container">
                {intMap.map((e) => {
                  return (
                    <div className="flex-item">
                      <img
                        src={e.img}
                        style={{ height: "50px", width: "50px" }}
                        key={e}
                        id={e.name}
                        onClick={(e) =>
                          onClick("Finish", e.target.id, e.target.src)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </Collapsible> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Form;
