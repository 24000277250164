import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import "./style.css";
import {
  drawerAnimate,
  doorAnimate,
  map,
  claspAnimate,
  topAnimate,
  blendAnimate,
  doorClaspAnimate,
  mirrorAnimate,
} from "./helpers/animations";
import Form from "./Form";
import {
  isTablet,
  MobileView,
  TabletView,
  isMobile,
  isMobileOnly,
} from "react-device-detect";
import Carousel from "react-elastic-carousel";

function Player() {
  const [displayAttr, setDisplayAttr] = useState([]);
  const [loading, setLoading] = useState(true);
  const [percent, setPercent] = useState(0);
  const [showConfig, setShowConfig] = useState(false);

  let counter = 0;

  let doorRotation;

  function setConfig(config, val) {
    window.configurator.setConfiguration({ [config]: val });
  }

  function drawerCheck() {
    if (map.d1) {
      drawerAnimate("d1", "in");
    }
    if (map.d2) {
      drawerAnimate("d2", "in");
    }
    if (map.d3) {
      drawerAnimate("d3", "in");
    }
    if (map.d4) {
      drawerAnimate("d4", "in");
    }
    if (map.d5) {
      drawerAnimate("d5", "in");
    }
    if (map.d6) {
      drawerAnimate("d6", "in");
    }
    if (map.d7) {
      drawerAnimate("d7", "in");
    }
    if (map.d8) {
      drawerAnimate("d8", "in");
    }
    if (map.boiteBijoux) {
      drawerAnimate("boiteBijoux", "in");
    }
  }

  function mirrorCheck() {
    if (map.mirror) {
      mirrorAnimate("Mirror", "close");
    }
  }
  function initPlayer() {
    setLoading(true);

    for (let [key, value] of Object.entries(map)) {
      value = false
    }
    window
      .threekitPlayer({
        authToken: "885168e3-e3a9-4f3c-b324-64415cc161e6",
        // Local 94853baa-6930-4536-835f-95ad8dd64a86
        // Deploy 885168e3-e3a9-4f3c-b324-64415cc161e6
        el: document.getElementById("player"),
        // stageId: "f8562726-af09-41b5-bb81-c3dc56e7e7fb",
        // Asset
        assetId: "b9755693-8b63-4737-8ccf-65de4b71f3cd",
        // catalog item
        // assetId: "f9cd63ce-3e9d-49d1-96ee-92d06d0d9/4cb",
        // stage
        stageId: "18489b9e-49fa-49ab-a032-0208e451825e",
        showAR: false,
        showLoadingProgress: false,
        showLoadingThumbnail: true,
        onLoadingProgress: (e) =>
          e > 0.99 ? setPercent(99) : setPercent((e * 100).toFixed(0)),
        allowMobileVerticalOrbit: true,
      })
      .then(async (api) => {
        window.player = api;
        window.configurator = await api.getConfigurator();
        await window.configurator.prefetchAttributes(["Finish"]);

        window.privateConfig = window.player
          .enableApi("player")
          .getConfigurator();

        setDisplayAttr(window.configurator.getDisplayAttributes());
        api.on(window.player.scene.PHASES.RENDERED, () => setLoading(false));
        doorRotation = window.configurator.getConfiguration()["Door Open"];

        window.player.tools.addTool({
          key: "partSelect",
          label: "Part Select Tool",
          active: true,
          enabled: true,
          handlers: {
            click: (ev) => {
              if (ev.originalEvent.type == "mouseup") {
                const hits = ev.hitNodes;
                const nodeId = hits && hits.length > 0 && hits[0].nodeId;
                if (nodeId) {
                  const selectable = window.player.scene.get({
                    id: nodeId,
                    plug: "Properties",
                    property: "selectable",
                  });
                  if (selectable || typeof selectable === "undefined") {
                    //   window.player.selectionSet.set(nodeId);
                    const nodeInfo = window.player.scene.get({ id: nodeId });
                    let newName = nodeInfo.name.split("_")[0];
                    let nullName = window.player.scene.get({
                      id: nodeInfo.parent,
                    }).name;
                    console.log(nullName);
                    console.log(map);

                    if (nullName == "door") {
                      map["door"]
                        ? doorAnimate("door", "close")
                        : doorAnimate("door", "open");
                      map["door"]
                        ? claspAnimate("metalHinge_GEO", "close")
                        : claspAnimate("metalHinge_GEO", "open");

                      map["door"]
                        ? doorClaspAnimate("centerHinge_GEO", "close")
                        : setTimeout(
                            () => doorClaspAnimate("centerHinge_GEO", "open"),
                            200
                          );
                      //claspAnimate("metalHinge_GEO", "close")
                      // Test drawers closing if open
                      drawerCheck();
                      // end test
                    } else if (nullName == "top") {
                      map["top"]
                        ? topAnimate("top", "close")
                        : topAnimate("top", "open");
                      mirrorCheck();

                      // map["top"]
                      //   ? blendAnimate("close", "exteriorLeather_Back_GEO")
                      //   : blendAnimate("open", "exteriorLeather_Back_GEO");
                      // map["top"]
                      // ? blendAnimate("close", "interiorBack_GEO")
                      // : blendAnimate("open", "interiorBack_GEO");
                    } else if (nullName == "Mirror") {
                      map["mirror"]
                        ? mirrorAnimate("Mirror", "close")
                        : mirrorAnimate("Mirror", "open");

                      // map["mirror"]
                      // ? mirrorAnimate("mirrorBody_GEO", "open")
                      // : mirrorAnimate("mirrorBody_GEO", "close");
                    } else {
                      map[nullName]
                        ? drawerAnimate(nullName, "in")
                        : drawerAnimate(nullName, "out");
                    }
                  } else {
                    window.player.selectionSet.clear();
                  }
                } else {
                  window.player.selectionSet.clear();
                }
              }
            },
          },
        });
      });
  }

  useEffect(() => {
    // Loaded

    initPlayer();
  }, []);

  return (
    <div className="player-container">
      {/* style={{ height: isMobileOnly ? "50vh" : "60vh" }} */}
      <div
        id="player"
        className={showConfig ? "player-config" : "player"}
      ></div>
      {loading ? (
        <center>
          <b style={{ fontSize: "25px" }} className="bold">
            Malle Vendome - {percent}% Loaded
          </b>
        </center>
      ) : (
        <div>
          <center>
            <button
              style={{
                backgroundColor: "#000000",
                color: "white",
                fontSize: "25px",
                fontWeight: "bold",
                margin: "2px",
              }}
              onClick={() =>
                showConfig ? setShowConfig(false) : setShowConfig(true)
              }
            >
              {showConfig ? "END" : "START"} PERSONALIZATION
            </button>
          </center>
          <div
            className="attribute-list"
            style={{ display: showConfig ? "block" : "none" }}
          >
            <Form />
          </div>
        </div>
      )}
    </div>
  );
}

export default Player;
