export let map = {
  d1: false,
  d2: false,
  d3: false,
  d4: false,
  d5: false,
  d6: false,
  d7: false,
  d8: false,
  door: false,
  boiteBijoux: false,
  top: false,
  clasp: false,
  blend: false,
  doorClasp: false,
  mirror: false
};
export function drawerAnimate(item, dir) {
  if (item == "d1") {
    // setD1Open(d1open ? false : true);
    map.d1 = map.d1 ? false : true;
  } else if (item == "d2") {
    // setD2Open(d2open ? false : true);
    map.d2 = map.d2 ? false : true;
  } else if (item == "d3") {
    // setD3Open(d3open ? false : true);
    map.d3 = map.d3 ? false : true;
  } else if (item == "d4") {
    // setD3Open(d3open ? false : true);
    map.d4 = map.d4 ? false : true;
  } else if (item == "d5") {
    // setD3Open(d3open ? false : true);
    map.d5 = map.d5 ? false : true;
  } else if (item == "d6") {
    // setD3Open(d3open ? false : true);
    map.d6 = map.d6 ? false : true;
  } else if (item == "d7") {
    // setD3Open(d3open ? false : true);
    map.d7 = map.d7 ? false : true;
  } else if (item == "d8") {
    // setD3Open(d3open ? false : true);
    map.d8 = map.d8 ? false : true;
  } else if (item == "boiteBijoux") {
    // setBottomOpen(bottomOpen ? false : true);
    map.boiteBijoux = map.boiteBijoux ? false : true;
  } else if (item == "door") {
    // setBottomOpen(bottomOpen ? false : true);
    map.door = map.door ? false : true;
  } else if (item == "top") {
    // setBottomOpen(bottomOpen ? false : true);
    map.top = map.top ? false : true;
  } else {
    return;
  }

  let player = window.player;
  let start;
  let startPos = player.scene.get({
    name: item,
    plug: "Transform",
    property: "translation",
  }).y;
  let endPos = 0.25;
  let totalDist = 0.25;
  let maxTime = 1000; // in milliseconds
  function step(timestamp) {
    if (start === undefined) start = timestamp;
    const elapsed = timestamp - start;
    const elapsedPercent = elapsed / maxTime;
    // Adding animation percent to capture motion as defined by easing function
    const animPercent = easeInOutCubic(elapsedPercent);

    const nodePos = player.scene.get({
      name: item,
      plug: "Transform",
      property: "translation",
    });
    if (dir == "in") {
      nodePos.y = Math.min(startPos + totalDist * animPercent, endPos);
    } else if (dir == "out") {
      nodePos.y = Math.min(startPos - totalDist * animPercent, endPos);
    }
    player.scene.set(
      {
        name: item,
        plug: "Transform",
        property: "translation",
      },
      nodePos
    );
    if (elapsed < maxTime) {
      window.requestAnimationFrame(step);
    }
  }
  //Define easing function
  function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  window.requestAnimationFrame(step);
}

export function claspAnimate(item, dir) {
  // setBottomOpen(bottomOpen ? false : true);
  map.clasp = map.clasp ? false : true;

  let player = window.player;
  let start;
  let startPos = player.scene.get({
    name: item,
    plug: "Transform",
    property: "rotation",
  }).z;
  let endPos = 180;
  let totalDist = 180;
  let maxTime = 1000; // in milliseconds
  function step(timestamp) {
    if (start === undefined) start = timestamp;
    const elapsed = timestamp - start;
    const elapsedPercent = elapsed / maxTime;
    // Adding animation percent to capture motion as defined by easing function
    const animPercent = easeInOutCubic(elapsedPercent);

    const nodePos = player.scene.get({
      name: item,
      plug: "Transform",
      property: "rotation",
    });
    if (dir == "open") {
      nodePos.z = Math.min(startPos + totalDist * animPercent, endPos);
    } else if (dir == "close") {
      nodePos.z = Math.min(startPos - totalDist * animPercent, endPos);
    }
    player.scene.set(
      {
        name: item,
        plug: "Transform",
        property: "rotation",
      },
      nodePos
    );
    if (elapsed < maxTime) {
      window.requestAnimationFrame(step);
    }
  }
  //Define easing function
  function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  window.requestAnimationFrame(step);
}

export function doorClaspAnimate(item, dir) {
  // setBottomOpen(bottomOpen ? false : true);
  map.doorClasp = map.doorClasp ? false : true;

  let player = window.player;
  let start;
  let startPos = player.scene.get({
    name: item,
    plug: "Transform",
    property: "rotation",
  }).x;
  let endPos = 180;
  let totalDist = 180;
  let maxTime = 1000; // in milliseconds
  function step(timestamp) {
    if (start === undefined) start = timestamp;
    const elapsed = timestamp - start;
    const elapsedPercent = elapsed / maxTime;
    // Adding animation percent to capture motion as defined by easing function
    const animPercent = easeInOutCubic(elapsedPercent);

    const nodePos = player.scene.get({
      name: item,
      plug: "Transform",
      property: "rotation",
    });
    if (dir == "open") {
      nodePos.x = Math.min(startPos + totalDist * animPercent, endPos);
    } else if (dir == "close") {
      nodePos.x = Math.min(startPos - totalDist * animPercent, endPos);
    }
    player.scene.set(
      {
        name: item,
        plug: "Transform",
        property: "rotation",
      },
      nodePos
    );
    if (elapsed < maxTime) {
      window.requestAnimationFrame(step);
    }
  }
  //Define easing function
  function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  window.requestAnimationFrame(step);
}
export function mirrorAnimate(item, dir, func) {
  if (item == "Mirror") {
    // setBottomOpen(bottomOpen ? false : true);
    map.mirror = map.mirror ? false : true;
  }

  let player = window.player;
  let start;
  let startPos = player.scene.get({
    name: item,
    plug: "Transform",
    property: "translation",
  }).z;
  let endPos = 1.25;
  let totalDist = 0.1;
  let maxTime = 1000; // in milliseconds
  function step(timestamp) {
    if (start === undefined) start = timestamp;
    const elapsed = timestamp - start;
    const elapsedPercent = elapsed / maxTime;
    // Adding animation percent to capture motion as defined by easing function
    const animPercent = easeInOutCubic(elapsedPercent);

    const nodePos = player.scene.get({
      name: item,
      plug: "Transform",
      property: "translation",
    });
    if (dir == "open") {
      nodePos.z = Math.min(startPos + totalDist * animPercent, endPos);
    } else if (dir == "close") {
      nodePos.z = Math.min(startPos - totalDist * animPercent, endPos);
    }
    player.scene.set(
      {
        name: item,
        plug: "Transform",
        property: "translation",
      },
      nodePos
    );
    if (elapsed < maxTime) {
      window.requestAnimationFrame(step);
    }
  }
  //Define easing function
  function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  window.requestAnimationFrame(step);
}

export function doorAnimate(item, dir, func) {
  if (item == "door") {
    // setBottomOpen(bottomOpen ? false : true);
    map.door = map.door ? false : true;
  }

  let player = window.player;
  let start;
  let startPos = player.scene.get({
    name: item,
    plug: "Transform",
    property: "rotation",
  }).z;
  let endPos = 125;
  let totalDist = 125;
  let maxTime = 1000; // in milliseconds
  function step(timestamp) {
    if (start === undefined) start = timestamp;
    const elapsed = timestamp - start;
    const elapsedPercent = elapsed / maxTime;
    // Adding animation percent to capture motion as defined by easing function
    const animPercent = easeInOutCubic(elapsedPercent);

    const nodePos = player.scene.get({
      name: item,
      plug: "Transform",
      property: "rotation",
    });
    if (dir == "open") {
      nodePos.z = Math.min(startPos + totalDist * animPercent, endPos);
    } else if (dir == "close") {
      nodePos.z = Math.min(startPos - totalDist * animPercent, endPos);
    }
    player.scene.set(
      {
        name: item,
        plug: "Transform",
        property: "rotation",
      },
      nodePos
    );
    if (elapsed < maxTime) {
      window.requestAnimationFrame(step);
    }
  }
  //Define easing function
  function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  window.requestAnimationFrame(step);
}
export function topAnimate(item, dir, func) {
  console.log(item, dir);
  // setBottomOpen(bottomOpen ? false : true);
  map.top = map.top ? false : true;

  let player = window.player;
  let start;
  let startPos = player.scene.get({
    name: item,
    plug: "Transform",
    property: "rotation",
  }).x;
  let endPos = 60;
  let totalDist = 60;
  let maxTime = 1000; // in milliseconds
  function step(timestamp) {
    if (start === undefined) start = timestamp;
    const elapsed = timestamp - start;
    const elapsedPercent = elapsed / maxTime;
    // Adding animation percent to capture motion as defined by easing function
    const animPercent = easeInOutCubic(elapsedPercent);

    const nodePos = player.scene.get({
      name: item,
      plug: "Transform",
      property: "rotation",
    });
    if (dir == "open") {
      nodePos.x = Math.min(startPos - totalDist * animPercent, endPos);
    } else if (dir == "close") {
      nodePos.x = Math.min(startPos + totalDist * animPercent, endPos);
    }
    player.scene.set(
      {
        name: item,
        plug: "Transform",
        property: "rotation",
      },
      nodePos
    );
    if (elapsed < maxTime) {
      window.requestAnimationFrame(step);
    }
  }
  //Define easing function
  function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  window.requestAnimationFrame(step);
}
export function blendAnimate(dir, mesh, func) {
  console.log('blend', dir);
  // setBottomOpen(bottomOpen ? false : true);
  map.blend = map.blend ? false : true;

  let player = window.player;
  let start;
  let startPos = player.scene.get({
    name: mesh,
    plug: "PolyMesh",
    property: "beta0",
  });
  let endPos = 1;
  let totalDist = 1;
  let maxTime = 1000; // in milliseconds
  function step(timestamp) {
    if (start === undefined) start = timestamp;
    const elapsed = timestamp - start;
    const elapsedPercent = elapsed / maxTime;
    // Adding animation percent to capture motion as defined by easing function
    const animPercent = easeInOutCubic(elapsedPercent);

    let nodePos = player.scene.get({
      name: mesh,
      plug: "PolyMesh",
      property: "beta0",
    });
    if (dir == "open") {
      nodePos = Math.min(startPos + totalDist * animPercent, endPos);
    } else if (dir == "close") {
      nodePos = Math.min(startPos - totalDist * animPercent, endPos);
    }
    player.scene.set(
      {
        name: mesh,
        plug: "PolyMesh",
        property: "beta0",
      },
      nodePos
    );
    if (elapsed < maxTime) {
      window.requestAnimationFrame(step);
    }
  }
  //Define easing function
  function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  window.requestAnimationFrame(step);
}
