import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import Player from "../Player";
import ReactGA from "react-ga";

// Firebase Config

const config = {
  apiKey: "AIzaSyBwhGwGmXwZlVJTkq-2nXKB8-p24kM5RD8",
  authDomain: "vivatech-3b670.firebaseapp.com",
  projectId: "vivatech-3b670",
  storageBucket: "vivatech-3b670.appspot.com",
  messagingSenderId: "29154355214",
  appId: "1:29154355214:web:fd4abf7c77b7c0a524b45f",
  measurementId: "G-J6GCZPV758",
};
function Auth() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState();

  const onChange = (e) => {
    if (e.target.name == "email") {
      setUsername(e.target.value);
    } else if (e.target.name == "password") {
      setPassword(e.target.value);
    }
  };
  const signIn = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)

      .then((userCredential) => {
        // Signed in

        var user = userCredential.user;
        console.log(user);
        setUser(user);
        setLoggedIn(true);
        localStorage.setItem('user', JSON.stringify(user));
        window.location.reload()
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setError(errorMessage);
      });
  };

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        setLoggedIn(false);
        localStorage.removeItem('user');
        window.location.reload();
      })
      .catch((error) => {
        // An error happened.
      });
  };
  useEffect(() => {
    ReactGA.initialize("UA-63905846-9");
    ReactGA.set({ dimension1: "Vivatech" });
    ReactGA.pageview("Vivatech - Landing");

    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    } else {
      firebase.app(); // if already initialized, use that one
    }
    // firebase.auth().onAuthStateChanged(function (user) {
    //   // setLoggedIn(true);
    //   console.log(user);
    //   console.log("loggedin?");
    //   setLoggedIn(true)
    // });
  }, []);

  return (
    <div>
      {localStorage.getItem('user') ? (
        <div>
          <Player />
          <p style={{ position: 'fixed', bottom: '0px', right: '0px', margin: '5px'}} onClick={signOut}>Sign Out</p>
        </div>
      ) : (
        <div className="auth">
          <p style={{ fontSize: "20px", margin: "5px" }}>
            Please log in to view demo
          </p>
          <p>{error}</p>

          <label for="email">Email</label>
          <input
            type="text"
            id="email"
            name="email"
            className="auth-el"
            required
            onChange={onChange}
          />
          <br />

          <label for="password">Password</label>
          <input
            className="auth-el"
            type="password"
            id="password"
            name="password"
            required
            onChange={onChange}
          />
          <br />

          <button onClick={signIn}>Sign In</button>
        </div>
      )}
    </div>
  );
}

export default Auth;
