export const iosMap = [
  {
    name: "Monogram Rose Ballerine",
    ar:
      "https://admin-fts.threekit.com/api/files/5c447374-37ab-4211-91b1-419a3907ed58/content",
  },
  {
    name: "Monogram Piment",
    ar:
      "https://admin-fts.threekit.com/api/files/5c447374-37ab-4211-91b1-419a3907ed58/content",
  },
  {
    name: "Damier Azur Rose Ballerine",
    ar:
      "https://admin-fts.threekit.com/api/files/064f49a7-5a2d-45e5-b3ca-c1b6ab168c14/content",
  },
  {
    name: "Epi Saphir Bleu Azur",
    ar:
      "https://admin-fts.threekit.com/api/files/09dd51c2-c539-46a6-a76a-1e64aeb49f0b/content",
  },
];
export const androidMap = [
  {
    name: "Monogram Rose Ballerine",
    ar:
      "https://admin-fts.threekit.com/api/files/0a8f9e9a-5126-4179-966f-c5696c4b679a/content",
  },
  {
    name: "Monogram Piment",
    ar:
      "https://admin-fts.threekit.com/api/files/0a8f9e9a-5126-4179-966f-c5696c4b679a/content",
  },
  {
    name: "Damier Azur Rose Ballerine",
    ar:
      "https://admin-fts.threekit.com/api/files/f7bd4c7e-fbac-4b58-99ab-e64a6e77fe47/content",
  },
  {
    name: "Epi Saphir Bleu Azur",
    ar:
      "https://admin-fts.threekit.com/api/files/a9ee323f-5a0a-4c89-a3eb-93522ac01519/content",
  },
];

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export function launchAR(config) {
  document.getElementById("status").innerText = "Launching...";
  if (getMobileOperatingSystem() == "iOS") {
    const arlink = document.getElementById("ar-ios-link");
    iosMap.forEach((e) => {
      if (e.name == config) {
        arlink.href = e.ar;
      }
    });
    // arlink.href = iosMap[config].ar
    arlink.click();
    setTimeout(function () {
      document.getElementById("status").innerText = "";
    }, 5000);
  } else if (getMobileOperatingSystem() == "Android") {
    const anchor = document.createElement("a");
    const location = window.location.toString();
    const locationUrl = new URL(location);
    const noArViewerSigil = "#no-ar-fallback";
    let modelUrl;
    // = new URL(
    //   await`https://admin-fts.threekit.com/api/files/${
    //     JSON.parse(Http.response).fileId
    //   }/content`
    // );
    let simpleURL;

    androidMap.forEach((e) => {
      if (e.name == config) {
        modelUrl = new URL(e.ar);
        simpleURL = e.ar;
      }
    });
    const link = encodeURIComponent(location);
    const scheme = modelUrl.protocol.replace(":", "");
    locationUrl.hash = noArViewerSigil;
    const title = encodeURIComponent("title");
    modelUrl.protocol = "intent://";
    //    intent://arvr.google.com/scene-viewer/1.0?file=https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/master/2.0/Avocado/glTF/Avocado.gltf#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;

    const intent = `intent://arvr.google.com/scene-viewer/1.0?file=${simpleURL}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(
      locationUrl.toString()
    )};end;`;

    anchor.setAttribute("href", intent);
    anchor.click();
    setTimeout(function () {
      document.getElementById("status").innerText = "";
    }, 5000);
  } else if(getMobileOperatingSystem() == "unknown"){
    setTimeout(function () {
      document.getElementById("status").innerText =
        "Please visit on an AR compatible device.";
    }, 5000);
  }
}
